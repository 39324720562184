<template>
    <div>
        <div class="top_bg" style="background-image: url('img/magicPlace/top_bg.png');">
        <back-nav :icon="'img/backw.png'" :textColor="'#fff'" :backColor="'none'" />
        <div class="play_tips" style="background-image: url('img/magicPlace/play_tips.png');">
                <div class="play_tips_title" style="background-image: url('img/magicPlace/title.png');"></div>
        </div>
    </div>
    <div class="list_bg" style="background-image: url('img/magicPlace/bottom_bg.png');">
        <div class="bronze_bag" style="background-image: url('img/magicPlace/egg_bg.png');">
            <div class="bronze_title_bg" style="background-image: url('img/magicPlace/title1.png');"></div>
            <div class="gift_box">
                <div v-for="(item, index) in info1" :key="index">
                    <div class="gift_item" style="background-image: url('img/magicPlace/gift_bg.png');">
                        <div class="sub_bg" style="background-image: url('img/magicPlace/sub_bg.png');">
                            <img class="gold" src="img/bi.png" alt="">
                            <span class="value">{{ item.diamonds }}</span>
                        </div>
                        <img class="gift_img" :src="item.gift_url" alt="">
                    </div>
                    <div class="gift_name">{{ item.gift_name }}</div>
                </div>

            </div>
        </div>
        <div class="bronze_bag" style="background-image: url('img/magicPlace/egg_bg.png');">
            <div class="bronze_title_bg" style="background-image: url('img/magicPlace/title2.png');"></div>
            <div class="gift_box">
                <div v-for="(item, index) in info2" :key="index">
                    <div class="gift_item" style="background-image: url('img/magicPlace/gift_bg.png');">
                        <div class="sub_bg" style="background-image: url('img/magicPlace/sub_bg.png');">
                            <img class="gold" src="img/bi.png" alt="">
                            <span class="value">{{ item.diamonds }}</span>
                        </div>
                        <img class="gift_img" :src="item.gift_url" alt="">
                    </div>
                    <div class="gift_name">{{ item.gift_name }}</div>
                </div>

            </div>
        </div>
        <div class="bronze_bag" style="background-image: url('img/magicPlace/egg_bg.png');">
            <div class="bronze_title_bg" style="background-image: url('img/magicPlace/title3.png');"></div>
            <div class="gift_box">
                <div v-for="(item, index) in info3" :key="index">
                    <div class="gift_item" style="background-image: url('img/magicPlace/gift_bg.png');">
                        <div class="sub_bg" style="background-image: url('img/magicPlace/sub_bg.png');">
                            <img class="gold" src="img/bi.png" alt="">
                            <span class="value">{{ item.diamonds }}</span>
                        </div>
                        <img class="gift_img" :src="item.gift_url" alt="">
                    </div>
                    <div class="gift_name">{{ item.gift_name }}</div>
                </div>

            </div>
        </div>
        <div class="special_detail" style="background-image: url('img/magicPlace/special_tips.png');"></div>
        <div class="final_detail">本活动最终解释权归光恋所有</div>
    </div>
    </div>
   
</template>
<script>
import "@/assets/css/magicPlace.css"
import BackNav from "@/components/backNav.vue";
import { starPlanInfo } from '@/api/api'
export default {
    components: {
        BackNav,
  },
    data() {
        return {
            info1: [],
            info2: [],
            info3:[]
        }
    },
    created() {
        let access_token = this.$route.query.access_token
        localStorage.setItem('access_token', access_token)
        this.getInfo()
    },
    methods: {
        getInfo() {
            let params = {
                type:1
            }
            starPlanInfo(params).then(res => {
                this.info1 = res.data.data[0].gift_info
                this.info2 = res.data.data[1].gift_info
                this.info3 = res.data.data[2].gift_info
            })
        }
    }
}
</script>
 