<template>
  <div class="nav">
    <van-nav-bar :style="{ background: backColor, border: backColor }">
      <template #title>
        <span :style="{ color: textColor }">{{ title }}</span>
      </template>
      <template #left>
        <van-image class="icon" fit="contain" :src="icon" @click="closePage" />
      </template>
      <template #right v-if="rightbg">
        <img class="right" :src="rightbg" @click="goSearch" />
      </template>
    </van-nav-bar>
  </div>
</template>
  
  <script>
export default {
  name: "backNav",
  props: {
    icon: String,
    textColor: String,
    backColor: String,
    rightbg: String,
    rightColor: String,
    backClick: Number,
  },
  data() {
    return {};
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
  methods: {
    closePage() {
      let params = "调用";
      this.$bridge.callhandler("closePage", params, (data) => {
        // 处理返回数据
      });
    },
    goSearch() {
      this.$router.push({ path: "/search" });
    },
  },
};
</script>
  
  <style>
.van-nav-bar::after {
  border: none;
}
</style>
  <style scoped>
  .nav{
     /* position: fixed; */
     width: 100vw;
     margin-top: 64px;
     /* top: 88px;
     left: 0; */
     z-index: 99999;
  }
.icon {
  width: 20px;
  height: 35px;
  margin-left: 20px;
}
.right {
  width: 45px;
}
</style>