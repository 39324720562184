import request from '@/utils/request'

//我的邀请
export function inviteInfo(data){
  return request({
    url:'/web/invite/myInviteInfo',
    method:'post',
    data
  })
}

//我的邀请详情
export function myInviteDetail(data){
  return request({
    url:'/web/invite/myInviteList',
    method:'post',
    data
  })
}

//查询
export function searchInvite(data){
  return request({
    url:'/web/invite/myInviteSearch',
    method:'post',
    data
  })
}

//子级邀请记录
export function sonInviteDetail(data){
  return request({
    url:'/V2/Invite/sonInviteDetail',
    method:'post',
    data
  })
}

//邀请提现记录
export function withdrawalRecord(data){
  return request({
    url:'/V2/Invite/withdrawalRecord',
    method:'post',
    data
  })
}

//邀请提现
export function withdrawalApply(data){
  return request({
    url:'/V2/Invite/withdrawalApply',
    method:'post',
    data
  })
}

//获取邀请码和二维码
export function getQRcode(data){
  return request({
    url:'/web/invite/getQrCode',
    method:'post',
    data
  })
}

//任务列表
export function welFareList(data){
  return request({
    url:'/V2/WelFare/welFareList',
    method:'post',
    data
  })
}

//领取幸运任务
export function getLuckyWelfare(data){
  return request({
    url:'/V2/WelFare/getLuckyWelfare',
    method:'post',
    data
  })
}

//领取每周任务、每日任务
export function getWelfare(data){
  return request({
    url:'/V2/WelFare/getWelfare',
    method:'post',
    data
  })
}

//新人领红包
export function newUserGetRedPacket(data){
  return request({
    url:'/web/recharge/wxRefund',
    method:'post',
    data
  })
}

//提现类目列表
export function withdrawList(data){
  return request({
    url:'/V2/WelFare/withdrawList',
    method:'post',
    data
  })
}

//花花提现
export function welFareWithdraw(data){
  return request({
    url:'/web/grow/flowerToMoney',
    method:'post',
    data
  })
}

//提现记录
export function welFareWithdrawLog(data){
  return request({
    url:'/V2/WelFare/welFareWithdrawLog',
    method:'post',
    data
  })
}

//花花余额
export function flowerBalance(data){
  return request({
    url:'/V2/WelFare/flowerBalance',
    method:'post',
    data
  })
}

//我的战绩
export function myGrade(data){
  return request({
    url:'/V2/PlayGuess/myGrade',
    method:'post',
    data
  })
}

//游戏记录
export function gameLog(data){
  return request({
    url:'/V2/PlayGuess/gameLog',
    method:'post',
    data
  })
}

//礼物记录详情
export function giftRecordDetail(data){
  return request({
    url:'/web/rank/giftRecordDetail',
    method:'post',
    data
  })
}

//等级弹框详情
export function inviteLevelDetails(data){
  return request({
    url:'/V2/WelFare/inviteLevelDetails',
    method:'post',
    data
  })
}

// 等级弹框领取花 
export function getFlowers(data){
  return request({
    url:'/V2/WelFare/getFlowers',
    method:'post',
    data
  })
}

//新人专享
export function newUser(data){
  return request({
    url:'/web/users/newUser',
    method:'post',
    data
  })
}

// 申请成为大神
export function applyForGod(data){
  return request({
    url:'/web/game/applyForGod',
    method:'post',
    data
  })
}

//游戏列表
export function gameList(data){
  return request({
    url:'/web/game/gameList',
    method:'post',
    data
  })
}


//订单列表
export function orderList(data){
  return request({
    url:'/web/game/orderList',
    method:'post',
    data
  })
}

//收益报表
export function orderEarnings(data){
  return request({
    url:'/web/game/orderEarnings',
    method:'post',
    data
  })
}

//大神中心
export function masterCentre(data){
  return request({
    url:'/web/game/masterCentre',
    method:'post',
    data
  })
}

//大神接单开关
export function controlReceiveOrder(data){
  return request({
    url:'/web/game/controlReceiveOrder',
    method:'post',
    data
  })
}

//历史通话记录
export function callRecord(data){
  return request({
    url:'/V2/audioVideoCall/callRecord',
    method:'post',
    data
  })
}

// 七夕活动榜单
export function sevenRankList(data){
  return request({
    url:'/Activity/Lover/rankList',
    method:'post',
    data
  })
}

// 七夕个人守护
export function personalRankList(data){
  return request({
    url:'/Activity/Lover/personalRankList',
    method:'post',
    data
  })
}

// 周星榜
export function ActivityWeek(data){
  return request({
    url:'/web/activity/activityWeek',
    method:'post',
    data
  })
}
//钻石流水记录

export function DiamondRecordLog(data){
  return request({
    url:'/web/log/diamondsLog',
    method:'post',
    data
  })
}
//提交会长资料
export function GuildUserRegist(data){
  return request({
    url:'/Qq/UnionProfit/upload',
    method:'post',
    data
  })
}
//公会流水
export function GuildWater(data){
  return request({
    url:'/Qq/UnionProfit/profit',
    method:'post',
    data
  })
}
//用户流水
export function UserWater(data){
  return request({
    url:'/Qq/UnionProfit/profitRank',
    method:'post',
    data
  })
}
//个人流水详情
export function UserWaterInfo(data){
  return request({
    url:'/Qq/UnionProfit/profitDetail',
    method:'post',
    data
  })
}

export function GetMode(data){
  return request({
    url:'/Qq/UnionProfit/index',
    method:'post',
    data
  })
}

//新人专享大转盘抽奖
export function NewUserLottery(data){
  return request({
    url:'/web/users/newUserDraw',
    method:'post',
    data
  })
}
// 红包记录列表
export function getPacketList(data){
  return request({
    url:'/Qq/RoomAccount/agentRechargeRecord ',
    method:'post',
    data
  })
}
//兑换记录
export function exchangeRecord(data){
  return request({
    url:'/web/wallet/exchangeRecord',
    method:'post',
    data
  })
}
//提现记录-钱包
export function withdrawRecord(data){
  return request({
    url:'/web/wallet/withdrawRecord',
    method:'post',
    data
  })
}
//收益收支报表（不包含开黑）
export function incomeRecord(data){
  return request({
    url:'/web/log/moneyLog',
    method:'post',
    data
  })
}
//提现报表
export function withdrawalLog(data){
  return request({
    url:'/web/log/withdrawalLog',
    method:'get',
    data
  })
}
//开黑收益报表
export function gameIncomeRecord(data){
  return request({
    url:'/web/game/orderEarnings',
    method:'post',
    data
  })
}
//赠送记录
export function sendRecord(data){
  return request({
    url:'/web/wallet/sendRecord',
    method:'post',
    data
  })
}
//福袋详情
export function luckyBagInfo(data){
  return request({
    url:'/web/gift/luckyBagDetail',
    method:'post',
    data
  })
}
//星动礼物详情
export function starPlanInfo(data){
  return request({
    url:'/web/gift/heartDetail',
    method:'post',
    data
  })
}

//七夕活动
export function saintRank(data){
  return request({
    url:'/web/activity/saintRank',
    method:'post',
    data
  })
}